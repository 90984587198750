<template>
  <div>
    <a-card title="出库通知单">
      <a-row :gutter="16">
        <a-col :span="24" :md="8" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-range-picker :value="[searchForm.start_date, searchForm.end_date]" @change="onChangePicker" />
        </a-col>
        <a-col :span="24" :md="8" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.warehouse" placeholder="仓库" allowClear style="width: 100%" @change="search">
            <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <client-select v-model="searchForm.client" placeholder="客户" @change="search"></client-select>
        </a-col>
        <a-col :span="24" :md="8" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-select placeholder="状态" allowClear style="width: 100%" @change="changeStatus">
            <a-select-option key="in_progress" value="in_progress">进行中</a-select-option>
            <a-select-option key="completed" value="completed">已完成</a-select-option>
            <a-select-option key="voided" value="voided">已作废</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" style="max-width: 260px; margin-bottom: 12px">
          <a-input v-model="searchForm.search" placeholder="单据编号, 客户单号, 备注" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 260px; margin-bottom: 12px">
          <a-input
            v-model="searchForm.fuzzy_search"
            placeholder="模糊查询"
            style="border: 1px solid red; border-radius: 4px; margin: -1px 0"
            allowClear
            @pressEnter="search"
          />
        </a-col>
        <a-col :span="24" :md="8" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.is_upload" placeholder="上传状态" allowClear style="width: 100%" @change="search">
            <a-select-option key="0" :value="true">已上传</a-select-option>
            <a-select-option key="1" :value="false">未上传</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="8" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.is_print" placeholder="打印状态" allowClear style="width: 100%" @change="search">
            <a-select-option key="0" :value="true">已打印</a-select-option>
            <a-select-option key="1" :value="false">未打印</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>

        <a-col :span="24" style="margin-bottom: 12px; width: 280px">
          <a-button-group>
            <a-button icon="file-excel" @click="downloadTemplate">模板下载</a-button>
            <a-upload name="file" :showUploadList="false" :customRequest="importExcel">
              <a-button icon="upload">导入</a-button>
            </a-upload>
            <a-button icon="download" @click="exportExcel">导出</a-button>
            <a-button icon="download" @click="queryExportExcel">查询导出</a-button>
          </a-button-group>
        </a-col>

        <a-col :span="24" style="margin-bottom: 12px; width: 280px">
          <a-button-group>
            <a-button icon="file-excel" @click="downloadSupplementTemplate">补录模板下载</a-button>
            <a-upload name="file" :showUploadList="false" :customRequest="importSupplementExcel">
              <a-button icon="upload">补录批量导入</a-button>
            </a-upload>
          </a-button-group>
        </a-col>

        <a-col :span="24" style="margin-bottom: 12px; width: 520px">
          <a-button-group>
            <a-button @click="printDeliveryOrder">打印送货单</a-button>
            <a-button @click="printPickingOrder">打印拣货单</a-button>
            <a-button @click="printAddress">打印送货地址</a-button>
            <a-button @click="summaryExport">汇总导出</a-button>
            <a-popconfirm title="确定删除吗?" @confirm="bulkDelete">
              <a-button type="danger" icon="delete">批量删除</a-button>
            </a-popconfirm>
          </a-button-group>
        </a-col>

        <div style="margin-bottom: 12px; float: right">
          <a-button type="primary" icon="plus" style="margin: 0 8px" @click="addOrder">新增出库通知单</a-button>
        </div>
      </a-row>

      <a-row>
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :data-source="items"
          :pagination="pagination"
          :loading="loading"
          :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :scroll="{ x: 2000, y: 480 }"
          @change="tableChange"
        >
          <template slot="number" slot-scope="value, item">
            <a @click="detail(item)">{{ value }}</a>
          </template>
          <div slot="logistics_number" slot-scope="value, item">
            <span v-if="item.is_void">已作废</span>
            <span v-else-if="value && value.length > 0">{{ value }}</span>
            <span v-else-if="item.delivery_method === '线下配送'">{{ item.express }}</span>
            <span v-else style="color: crimson">待录入</span>
          </div>
          <div slot="action" slot-scope="value, item">
            <a-button-group size="small">
              <!-- <a-button @click="detail(item)">详情</a-button> -->
              <a-button @click="openSupplementDeliveryModal(item)">补充</a-button>
              <a-button type="primary" @click="copyOrder(item)">复制</a-button>
              <!-- <a-popconfirm title="确定强制完成吗?" @confirm="complete(item)">
                <a-button type="primary">强制完成</a-button>
              </a-popconfirm> -->
              <a-popconfirm title="确定作废重建吗?" @confirm="voidAndRecreate(item)">
                <a-button type="danger">作废重建</a-button>
              </a-popconfirm>
              <a-popconfirm title="确定作废吗?" @confirm="voidItem(item)">
                <a-button type="danger">作废</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>

    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />正在导入中, 请等待...</div>
    </a-modal>

    <SupplementDeliveryModal v-model="supplementVisible" :form="targetItem" @update="list" />
    <UpdateModal v-model="updateModalVisible1" :items="updateItems" @cancel="list" @confirm="printDeliveryOrder({}, false)" />
    <UpdateModal v-model="updateModalVisible2" :items="updateItems" @cancel="list" @confirm="printPickingOrder({}, false)" />
  </div>
</template>

<script>
import {
  deliveryOrderTemplate,
  deliveryOrderImport,
  deliveryOrderSupplementTemplate,
  deliveryOrderSupplementImport,
} from "@/api/import";
import {
  deliveryOrderList,
  deliveryOrderVoid,
  deliveryOrderBulkDelete,
  deliveryOrderPrint,
  pickingOrderPrint,
  deliveryOrderComplete,
} from "@/api/stockOut";
import { deliveryOrdersExport, deliveryOrderSummaryExport, deliveryOrdersQueryExport } from "@/api/export";
import { warehouseOption } from "@/api/option";
import { exportExcel } from "@/utils/excel";
import { columns } from "./columns.js";
import moment from "moment";
import { getLodop } from "@/utils/LodopFuncs";
import NP from "number-precision";
import JsBarcode from "jsbarcode";

export default {
  components: {
    ClientSelect: () => import("@/components/ClientSelect/"),
    SupplementDeliveryModal: () => import("@/components/SupplementDeliveryModal/"),
    UpdateModal: () => import("./UpdateModal"),
  },
  data() {
    return {
      columns,
      searchForm: { search: "", page: 1, page_size: 48, ordering: undefined },
      pagination: { current: 1, total: 0, pageSize: 48 },
      loading: false,
      items: [],
      warehouseItems: [],

      visible: false,
      targetItem: {},
      form: {},

      selectedRowKeys: [],
      selectionRows: [],
      importLoading: false,

      supplementVisible: false,
      shengshiteLogo: require("@/assets/shengshite-logo.png"),
      updateItems: [],
      updateModalVisible1: false,
      updateModalVisible2: false,
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
    addressLabelWidth() {
      return this.$store.state.user.barConfig.addressLabelWidth;
    },
    addressLabelLength() {
      return this.$store.state.user.barConfig.addressLabelLength;
    },
  },
  methods: {
    initialize() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.$functions.parseParams(this.$route.query);
      this.searchForm = { ...this.searchForm, ...this.$functions.parseParams(this.$route.query) };
      this.list();

      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
      });
    },
    list() {
      let searchForm = { ...this.searchForm };
      if (searchForm.end_date) {
        searchForm.end_date = moment(searchForm.end_date).add(1, "days").format("YYYY-MM-DD");
      }

      this.loading = true;
      this.$router.push({ query: this.searchForm });
      deliveryOrderList(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.selectedRowKeys = [];
      this.selectionRows = [];
      this.list();
    },
    changeStatus(value) {
      if (value === "in_progress") {
        this.searchForm.is_void = false;
        this.searchForm.is_completed = false;
      } else if (value === "completed") {
        this.searchForm.is_void = false;
        this.searchForm.is_completed = true;
      } else if (value === "voided") {
        this.searchForm.is_void = true;
        this.searchForm.is_completed = undefined;
      }
      this.search();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onChangePicker(date, dateString) {
      let startDate = date[0],
        endDate = date[1];
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.format("YYYY-MM-DD") : undefined;
      this.search();
    },
    addOrder() {
      this.$router.push({ path: "/stock_out/delivery_order_create" });
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    detail(item) {
      this.$router.push({ path: "/stock_out/delivery_task_detail", query: { id: item.id } });
    },
    voidItem(item) {
      deliveryOrderVoid({ id: item.id }).then(() => {
        this.$message.success("作废成功");
        this.list();
      });
    },
    voidAndRecreate(item) {
      deliveryOrderVoid({ id: item.id }).then(() => {
        this.$message.success("作废成功");
        this.copyOrder(item);
      });
    },
    copyOrder(item) {
      this.$router.push({ path: "/stock_out/delivery_order_create", query: { id: item.id } });
    },
    complete(item) {
      deliveryOrderComplete({ id: item.id }).then(() => {
        this.$message.success("完成成功");
        this.list();
      });
    },
    exportExcel() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择要导出的出库通知单!");
        return false;
      }
      deliveryOrdersExport({ ids: this.selectedRowKeys })
        .then((resp) => {
          exportExcel(resp, "出库通知单列表");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    queryExportExcel() {
      let searchForm = { ...this.searchForm };
      if (searchForm.end_date) {
        searchForm.end_date = moment(searchForm.end_date).add(1, "days").format("YYYY-MM-DD");
      }

      deliveryOrdersQueryExport(searchForm)
        .then((resp) => {
          exportExcel(resp, "出库通知单列表");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    summaryExport() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择要导出的出库通知单!");
        return false;
      }
      deliveryOrderSummaryExport({ ids: this.selectedRowKeys })
        .then((resp) => {
          exportExcel(resp, "出库通知单汇总");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    downloadTemplate() {
      deliveryOrderTemplate()
        .then((resp) => {
          exportExcel(resp, "出库通知单导入模板");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    importExcel(item) {
      let data = new FormData();
      data.append("file", item.file);
      this.importLoading = true;
      setTimeout(() => {
        deliveryOrderImport(data)
          .then(() => {
            this.$message.success("导入成功");
            this.list();
          })
          .catch((err) => {
            alert("导入错误:\n" + err.response.data.join("\n"));
          })
          .finally(() => {
            this.importLoading = false;
          });
      }, 1000);
    },
    downloadSupplementTemplate() {
      deliveryOrderSupplementTemplate()
        .then((resp) => {
          exportExcel(resp, "出库通知单补录模板");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    importSupplementExcel(item) {
      let data = new FormData();
      data.append("file", item.file);
      this.importLoading = true;
      setTimeout(() => {
        deliveryOrderSupplementImport(data)
          .then(() => {
            this.$message.success("导入成功");
            this.list();
          })
          .catch((err) => {
            alert("导入错误:\n" + err.response.data.join("\n"));
          })
          .finally(() => {
            this.importLoading = false;
          });
      }, 1000);
    },
    bulkDelete() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择要删除的出库通知单!");
        return false;
      }

      deliveryOrderBulkDelete({ ids: this.selectedRowKeys }).then(() => {
        this.$message.success("批量删除成功");
        this.list();
      });
    },
    printText(value) {
      return value != null || value != undefined ? value : "";
    },
    // 打印送货单
    printDeliveryOrder(event, isCheck = true) {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择要打印的出库通知单!");
        return;
      }

      // 检查是否存在快递物流
      if (isCheck) {
        const updateItems = [];
        for (const item of this.selectionRows) {
          if (item.delivery_method === "线上配送") {
            if (!item.logistics_number || item.logistics_number.length === 0) {
              updateItems.push(item);
            }
          }
        }

        if (updateItems.length > 0) {
          this.updateItems = updateItems;
          this.updateModalVisible1 = true;
          return;
        }
      }

      const DeliveryPageCount = 14;
      deliveryOrderPrint({ ids: this.selectedRowKeys }).then((data) => {
        const LODOP = getLodop();
        LODOP.PRINT_INIT("Delivery order");
        LODOP.SET_PRINT_PAGESIZE(1, "210mm", "297mm");

        for (let deliveryOrderItem of data) {
          let pages = Math.ceil(deliveryOrderItem.delivery_material_items.length / DeliveryPageCount);

          let canvas = document.createElement("canvas");
          JsBarcode(canvas, deliveryOrderItem.number, {
            format: "CODE128",
            width: 2,
            height: 35,
            displayValue: true,
            background: "#ffffff",
            lineColor: "#000000",
          });
          let barcodeBase64 = canvas.toDataURL();

          let totalQuantity = 0;
          for (let item of deliveryOrderItem.delivery_material_items) {
            totalQuantity = NP.plus(totalQuantity, item.total_quantity);
          }

          for (let index = 1; index <= pages; index++) {
            let deliveryMaterialItems = deliveryOrderItem.delivery_material_items.slice(
              (index - 1) * DeliveryPageCount,
              index * DeliveryPageCount
            );
            let tableRows = deliveryMaterialItems.map((item) => {
              return `
<tr>
  <td>${this.printText(item.material_item.number)}</td>
  <td>${this.printText(item.material_item.name)}</td>
  <td>${this.printText(item.material_item.english_name)}</td>
  <td>${this.printText(item.material_item.unit)}</td>
  <td>${this.printText(item.total_quantity)}</td>
</tr>`;
            });
            const deliveryDate = deliveryOrderItem.delivery_date
              ? moment(deliveryOrderItem.delivery_date).format("YYYY年 MM月 DD日")
              : "";

            const pageContent = `
<div style="padding: 16px">
  <div style="font-size: 24px; font-weight: 600; border-bottom: 3px solid">
    <span>NO.${deliveryOrderItem.number}</span>
    <span style="float: right;">${pages > 1 ? index : ""}</span>
  </div>

  <div style="margin-top: 8px; font-size: 32px; font-weight: 600">
    <div style="width: 40%; margin: auto; border-bottom: 3px solid; display: flex">
      <div style="width: 33%; text-align: center">送</div>
      <div style="width: 34%; text-align: center">货</div>
      <div style="width: 33%; text-align: center">单</div>
    </div>
  </div>

  <div style="margin-top: 8px; font-size: 14px; display: flex">
    <div style="width: 50%; text-align: left; font-size: 22px;">出库日期: ${deliveryDate}</div>
    <div style="width: 50%; text-align: center">订单号: ${this.printText(deliveryOrderItem.external_number)}</div>
  </div>

  <div style="margin-top: 8px; display: flex;">
    <div style="width: 50%; text-align: center; font-size: 24px; font-weight: 600;">
      <div style="padding-top: 8px">${deliveryOrderItem.client_item.name}</div>
    </div>
    
    <div style="width: 50%; text-align: center; ">
      <img src="${barcodeBase64}" />
    </div>
  </div>

  <div style="margin-top: 8px; font-size: 14px; display: flex">
    <div style="width: 33%">收货人: ${this.printText(deliveryOrderItem.customer_contact)}</div>
    <div style="width: 34%">承运公司: ${this.printText(deliveryOrderItem.express)}</div>
    <div style="width: 33%; font-weight: bold">打包要求: ${this.printText(deliveryOrderItem.packaging_requirements)}</div>
  </div>

  <div style="margin-top: 8px; font-size: 14px; display: flex">
    <div style="width: 33%">联系电话: ${this.printText(deliveryOrderItem.contact_phone)}</div>
    <div style="width: 67%">物流单号: ${this.printText(deliveryOrderItem.logistics_number)}</div>
  </div>

  <div style="margin-top: 8px; font-size: 14px; display: flex">
    <div style="width: 100%"> 收货地址: ${this.printText(deliveryOrderItem.receiving_address)}</div>
  </div>

  <div style="margin-top: 8px; font-size: 16px; display: flex">
    <div style="width: 100%; color: #C41E3D; font-weight: bold">备注: ${this.printText(deliveryOrderItem.remark)}</div>
  </div>

  <div style="margin-top: 16px; font-size: 14px">
    <table border="1" cellspacing="0" style="width: 100%; text-align: center">
      <tr>
        <th style="width: 25%">商品编号</th>
        <th style="width: 25%">商品名称</th>
        <th style="width: 30%">英文名称</th>
        <th style="width: 10%">单位</th>
        <th style="width: 10%">数量</th>
      </tr>
      ${tableRows.join("")}
      <tr>
        <td colspan="2" style="text-align: left">合计: ${totalQuantity}</td>
        <td colspan="3" rowspan="2">
          为避免双方利益受损，针对以上项目请您在接受货物时务必同送货方进行确认，无误后再签收，签收后视为验收合格。货物完好.
        </td>
      </tr>
      <tr>
        <td colspan="2" style="text-align: left">总重量(KG):</td>
      </tr>
    </table>
  </div>

  <div style="margin-top: 16px; font-size: 14px; display: flex">
    <div style="width: 33%; border: 1px solid; padding: 4px">
      <div>发货人 （盖章/签字）</div>
      <div style="margin-top: 36px">发货日:</div>
    </div>
    <div style="width: 34%; border: 1px solid; padding: 4px; margin: 0 24px">
      <div>承运人 （盖章/签字）</div>
      <div style="margin-top: 36px">承运日:</div>
    </div>
    <div style="width: 33%; border: 1px solid; padding: 4px">
      <div>收货人 （盖章/签字）</div>
      <div style="margin-top: 36px">收货日:</div>
    </div>
  </div>
  
  <div style="margin-top: 16px; font-size: 14px; display: flex">
    <div style="width: 33%">创建时间: ${this.printText(deliveryOrderItem.create_time)}</div>
  </div>
</div>
            `;

            LODOP.NewPage();
            LODOP.ADD_PRINT_HTM(0, 0, "100%", "100%", pageContent);
          }
        }

        LODOP.PREVIEW();
      });
    },
    // 打印拣货单
    printPickingOrder(event, isCheck = true) {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择要打印的出库通知单!");
        return;
      }

      if (isCheck) {
        const updateItems = [];
        for (const item of this.selectionRows) {
          if (item.delivery_method === "线上配送") {
            if (!item.logistics_number || item.logistics_number.length === 0) {
              updateItems.push(item);
            }
          }
        }

        if (updateItems.length > 0) {
          this.updateItems = updateItems;
          this.updateModalVisible2 = true;
          return;
        }
      }

      const PickingOrderPageCount = 14;
      const pickingOrderIds = [];
      for (const selectedRowKey of this.selectedRowKeys) {
        let index = this.items.findIndex((item) => item.id == selectedRowKey);
        if (index !== -1) {
          pickingOrderIds.push(this.items[index].picking_order);
        }
      }

      pickingOrderPrint({ ids: pickingOrderIds }).then((data) => {
        const LODOP = getLodop();
        LODOP.PRINT_INIT("Picking order");
        LODOP.SET_PRINT_PAGESIZE(1, "210mm", "297mm");

        for (let pickingOrderItem of data) {
          let deliveryDate = pickingOrderItem.delivery_order_item.delivery_date;
          deliveryDate = deliveryDate ? moment(deliveryDate).format("YYYY年 MM月 DD日") : "";

          let pickingMaterialItems = [];
          let totalQuantity = 0;
          let totalWeight = 0;
          let totalVolume = 0;
          let canvas = document.createElement("canvas");
          JsBarcode(canvas, pickingOrderItem.number, {
            format: "CODE128",
            width: 2,
            height: 35,
            displayValue: true,
            background: "#ffffff",
            lineColor: "#000000",
          });
          let barcodeBase64 = canvas.toDataURL();

          for (let pickingMaterialItem of pickingOrderItem.picking_material_items) {
            totalQuantity = NP.plus(totalQuantity, pickingMaterialItem.total_quantity);
            totalWeight = NP.plus(
              totalWeight,
              NP.times(pickingMaterialItem.total_quantity, pickingMaterialItem.material_item.net_weight || 0)
            );
            totalVolume = NP.plus(
              totalVolume,
              NP.times(pickingMaterialItem.total_quantity, pickingMaterialItem.material_item.volume || 0)
            );

            if (pickingMaterialItem.recommend_location_items.length > 0) {
              for (let recommendLocationItem of pickingMaterialItem.recommend_location_items) {
                pickingMaterialItems.push({
                  material_number: pickingMaterialItem.material_item.number,
                  material_name: pickingMaterialItem.material_item.name,
                  english_name: pickingMaterialItem.material_item.english_name,
                  material_spec: pickingMaterialItem.material_item.spec,
                  total_quantity: recommendLocationItem.total_quantity,
                  batch_number: pickingMaterialItem.batch_item.number,
                  location_number: recommendLocationItem.location_number,
                  status_display: recommendLocationItem.status_display,
                  remain_quantity: NP.minus(
                    recommendLocationItem.inventory_quantity || 0,
                    recommendLocationItem.total_quantity
                  ),
                });
              }
            } else {
              pickingMaterialItems.push({
                material_number: pickingMaterialItem.material_item.number,
                material_name: pickingMaterialItem.material_item.name,
                english_name: pickingMaterialItem.material_item.english_name,
                material_spec: pickingMaterialItem.material_item.spec,
                total_quantity: pickingMaterialItem.total_quantity,
                batch_number: pickingMaterialItem.batch_item.number,
                location_number: "",
              });
            }
          }

          let pages = Math.ceil(pickingMaterialItems.length / PickingOrderPageCount);
          for (let index = 1; index <= pages; index++) {
            let items = pickingMaterialItems.slice((index - 1) * PickingOrderPageCount, index * PickingOrderPageCount);
            let tableRows = items.map((item) => {
              return `
<tr>
  <td>${this.printText(item.material_number)}</td>
  <td>${this.printText(item.material_name)}</td>
  <td>${this.printText(item.english_name)}</td>
  <td>${this.printText(item.material_spec)}</td>
  <td>${this.printText(item.total_quantity)}</td>
  <td>${this.printText(item.location_number)}</td>
  <td>${this.printText(item.remain_quantity)}</td>
</tr>`;
            });

            let pageContent = `
<div style="padding: 8px">
  <div style="font-size: 36px; text-align: center">
    <span>拣货单</span>
    <span style="float: right;">${pages > 1 ? index : ""}</span>
  </div>

  <div style="margin-top: 8px; font-size: 14px; display: flex">
    <div style="width: 50%; text-align: left; font-size: 22px;padding-top: 20px;">出库日期: ${this.printText(
      deliveryDate
    )}</div>
    <div style="width: 50%; text-align: right; font-size: 22px;"><img src="${barcodeBase64}" /></div>
  </div>


  <div style="margin-top: 8px; display: flex">
    <div style="width: 33%">客户: ${this.printText(pickingOrderItem.client_item.name)}</div>
    <div style="width: 34%">客户单号: ${this.printText(pickingOrderItem.delivery_order_item.external_number)}</div>
    <div style="width: 33%">出库单号: ${this.printText(pickingOrderItem.delivery_order_item.number)}</div>
  </div>

  <div style="margin-top: 8px; display: flex">
    <div style="width: 33%">库区: ${this.printText(pickingOrderItem.delivery_order_item.area_name)}</div>
    <div style="width: 34%">配送方式: ${this.printText(pickingOrderItem.delivery_order_item.delivery_method)}</div>
    <div style="width: 33%">承运公司: ${this.printText(pickingOrderItem.delivery_order_item.express)}</div>
  </div>

  <div style="margin-top: 8px; display: flex">
    <div style="width: 33%">物流单号: ${this.printText(pickingOrderItem.delivery_order_item.logistics_number)}</div>
    <div style="width: 34%; font-weight: bold">
      打包要求: ${this.printText(pickingOrderItem.delivery_order_item.packaging_requirements)}
    </div>
    <div style="width: 33%; color: #C41E3D; font-weight: bold; font-size: 16px">
      备注: ${this.printText(pickingOrderItem.delivery_order_item.remark)}
    </div>
  </div>

  <div style="margin-top: 8px; display: flex">
    <div style="width: 33%">送达方名称: ${this.printText(pickingOrderItem.delivery_order_item.region_name)}</div>
    <div style="width: 67%">送货地址: ${this.printText(pickingOrderItem.delivery_order_item.receiving_address)}</div>
  </div>

  <div style="margin-top: 8px; display: flex">
    <div style="width: 33%">仓库: ${this.printText(pickingOrderItem.warehouse_item.name)}</div>
    <div style="width: 34%">创建人: ${this.printText(pickingOrderItem.creator_item?.name)}</div>
  </div>

  <div style="margin-top: 8px; display: flex">
    <div style="width: 33%">总重量(吨): ${NP.times(totalWeight, 0.001)}</div>
    <div style="width: 34%">总体积(立方): ${totalVolume}</div>
  </div>

  <div style="margin-top: 16px; font-size: 14px">
    <table border="1" cellspacing="0" style="width: 100%; text-align: center">
      <tr>
        <th>产品编号</th>
        <th>产品名称</th>
        <th>英文名称</th>
        <th>包装规格</th>
        <th>应拣数量</th>
        <th>推荐库位</th>
        <th>剩余库存</th>
      </tr>
      ${tableRows.join("")}
      <tr>
        <td>合计:</td>
        <td></td>
        <td></td>
        <td></td>
        <td>${this.printText(totalQuantity)}</td>
        <td></td>
        <td></td>
      </tr>
    </table>
  </div>

  <div style="margin-top: 24px; font-size: 14px">
    拣货人:
  </div>
  <div style="margin-top: 24px; font-size: 14px">
      <div style="width: 33%">创建时间: ${this.printText(pickingOrderItem.create_time)}</div>
  </div>
</div>`;

            LODOP.NewPage();
            LODOP.ADD_PRINT_HTM(0, 0, "100%", "100%", pageContent);
          }
        }

        LODOP.PREVIEW();
      });
    },
    // 打印送货地址
    printAddress() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择要打印的出库通知单!");
        return;
      }

      const LODOP = getLodop();
      LODOP.PRINT_INIT("Print Address");
      LODOP.SET_PRINT_PAGESIZE(1, `${this.addressLabelLength}mm`, `${this.addressLabelWidth}mm`);
      for (const item of this.selectionRows) {
        const htmlContent = `
        <!DOCTYPE html>  
        <html lang="en">  
          <body>
            <div style="padding: 12px; font-size: 24px; font-weight: bold;">${item.receiving_address ?? ""}</div>
          </body>
        </html>`;

        LODOP.NewPage();
        LODOP.ADD_PRINT_HTM(0, 0, "100%", "100%", htmlContent);
      }

      LODOP.PREVIEW();
    },
    openSupplementDeliveryModal(item) {
      this.targetItem = item;
      this.supplementVisible = true;
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped></style>
